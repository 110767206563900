<section class="hero-home">
  <div class="hero-slider">
    <div class="swiper-wrapper dark-overlay">
      <div
        class="swiper-slide"
        style="
          background-image: url(assets/img/photo/photo-1534850336045-c6c6d287f89e.webp);
        "
      ></div>
    </div>
  </div>
  <div class="container py-6 py-md-7 text-white z-index-20">
    <div class="row">
      <div class="col-xl-10">
        <div class="text-center text-lg-start">
          <p class="subtitle letter-spacing-4 mb-2 text-secondary text-shadow">
            Only the best books
          </p>
          <h1 class="display-3 fw-bold text-shadow">Get some random book</h1>
        </div>
        <div class="search-bar mt-5 p-3 p-lg-1 ps-lg-4">
          <div class="row">
            <div
              class="col-lg-6 d-flex align-items-center form-group no-divider"
            >
              <div class="dropdown bootstrap-select">
                <select
                  class="selectpicker"
                  title="Categories"
                  data-style="btn-form-control"
                  tabindex="null"
                >
                  <option class="bs-title-option" value=""></option>
                  <option value="small">Restaurants</option>
                  <option value="medium">Hotels</option>
                  <option value="large">Cafes</option>
                  <option value="x-large">Garages</option>
                </select>
                <button
                  type="button"
                  tabindex="-1"
                  class="btn dropdown-toggle bs-placeholder btn-form-control show"
                  data-bs-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  title="Categories"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Categories</div>
                    </div>
                  </div>
                </button>
                <!--                <div-->
                <!--                  class="dropdown-menu show"-->
                <!--                  style="-->
                <!--                    position: absolute;-->
                <!--                    inset: auto auto 0px 0px;-->
                <!--                    margin: 0px;-->
                <!--                    transform: translate3d(0px, -45px, 0px);-->
                <!--                    max-height: 394.398px;-->
                <!--                    overflow: hidden;-->
                <!--                    min-height: 154px;-->
                <!--                  "-->
                <!--                  data-popper-placement="top-start"-->
                <!--                >-->
                <!--                  <div-->
                <!--                    class="inner show"-->
                <!--                    role="listbox"-->
                <!--                    id="bs-select-1"-->
                <!--                    tabindex="-1"-->
                <!--                    style="-->
                <!--                      max-height: 378.398px;-->
                <!--                      overflow-y: auto;-->
                <!--                      min-height: 138px;-->
                <!--                    "-->
                <!--                  >-->
                <!--                    <ul-->
                <!--                      class="dropdown-menu inner show"-->
                <!--                      role="presentation"-->
                <!--                      style="margin-top: 0px; margin-bottom: 0px"-->
                <!--                    >-->
                <!--                      <li>-->
                <!--                        <a-->
                <!--                          role="option"-->
                <!--                          class="dropdown-item"-->
                <!--                          id="bs-select-1-0"-->
                <!--                          tabindex="0"-->
                <!--                          aria-setsize="4"-->
                <!--                          aria-posinset="1"-->
                <!--                          ><span class="text">Restaurants</span></a-->
                <!--                        >-->
                <!--                      </li>-->
                <!--                      <li>-->
                <!--                        <a-->
                <!--                          role="option"-->
                <!--                          class="dropdown-item"-->
                <!--                          id="bs-select-1-1"-->
                <!--                          tabindex="0"-->
                <!--                          aria-setsize="4"-->
                <!--                          aria-posinset="2"-->
                <!--                          ><span class="text">Hotels</span></a-->
                <!--                        >-->
                <!--                      </li>-->
                <!--                      <li>-->
                <!--                        <a-->
                <!--                          role="option"-->
                <!--                          class="dropdown-item"-->
                <!--                          id="bs-select-1-2"-->
                <!--                          tabindex="0"-->
                <!--                          aria-setsize="4"-->
                <!--                          aria-posinset="3"-->
                <!--                          ><span class="text">Cafes</span></a-->
                <!--                        >-->
                <!--                      </li>-->
                <!--                      <li>-->
                <!--                        <a-->
                <!--                          role="option"-->
                <!--                          class="dropdown-item"-->
                <!--                          id="bs-select-1-3"-->
                <!--                          tabindex="0"-->
                <!--                          aria-setsize="4"-->
                <!--                          aria-posinset="4"-->
                <!--                          ><span class="text">Garages</span></a-->
                <!--                        >-->
                <!--                      </li>-->
                <!--                    </ul>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>

            <div class="col-lg-6 d-grid">
              <button
                class="btn btn-primary rounded-pill h-100 main-button"
                type="button"
                (click)="getBooks()"
              >
                Get books
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-4 bg-gray-100">
  <div class="container suggestions__section">
    <div class="text-center pb-lg-4" *ngIf="books">
      <h2 class="mb-3">Pick for you</h2>
    </div>
    <div class="row suggestions__row">
      <ng-container *ngFor="let book of books">
        <div class="col-lg-4 mb-3 mb-lg-0 text-center">
          <div class="px-0 px-lg-3">
            <div class="icon-rounded bg-primary-light mb-3">
              <svg class="svg-icon text-primary w-2rem h-2rem">
                <use xlink:href="#destination-map-1"></use>
              </svg>
            </div>
            <h3 class="h5">{{ book.author }} {{ book.name }}</h3>
            <p class="text-muted">
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed in
            </p>
            <div class="h-100">
              <div
                class="bb-widget"
                id="buybox-nb70"
                data-bb-id="16241"
                data-bb-oid="2711762"
                data-bb-type="popover"
                data-bb-number="9788363944483"
                data-bb-language="pl"
                data-bb-lead-color="#4E66F8"
                data-bb-row-count="5"
                data-bb-show-extra="true"
              >
                <span
                  style="
                    text-decoration: underline;
                    color: #4e66f8;
                    cursor: pointer;
                  "
                >
                  Sprawdź, gdzie kupić
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!books">
      <div class="text-center pb-lg-4">
        <h2 class="mb-5">No recommendations yet.</h2>
        <p class="subtitle text-secondary">Click "Get book"</p>
      </div>
    </ng-container>
  </div>
</section>
<!--<section class="py-7 position-relative dark-overlay">-->
<!--  <img-->
<!--    class="bg-image"-->
<!--    src="assets/img/photo/photo-1497436072909-60f360e1d4b1.jpg"-->
<!--    alt=""-->
<!--  />-->
<!--  <div class="container">-->
<!--    <div class="overlay-content text-white py-lg-5">-->
<!--      <h3 class="display-3 fw-bold text-serif text-shadow mb-5">-->
<!--        Ready for your next holidays?-->
<!--      </h3>-->
<!--      <a class="btn btn-light" href="category-rooms.html">Get started</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
<!--<section class="py-7">-->
<!--  <div class="container">-->
<!--    <div class="text-center">-->
<!--      <p class="subtitle text-primary">Testimonials</p>-->
<!--      <h2 class="mb-5">Our dear customers said about us</h2>-->
<!--    </div>-->
<!--    &lt;!&ndash; Slider main container&ndash;&gt;-->
<!--    <div class="swiper-container testimonials-slider testimonials">-->
<!--      &lt;!&ndash; Additional required wrapper&ndash;&gt;-->
<!--      <div class="swiper-wrapper pt-2 pb-5">-->
<!--        &lt;!&ndash; Slides&ndash;&gt;-->
<!--        <div class="swiper-slide p-4">-->
<!--          <div class="testimonial card rounded-3 shadow border-0">-->
<!--            <div class="testimonial-avatar">-->
<!--              <img-->
<!--                class="avatar avatar-lg p-1"-->
<!--                src="assets/img/avatar/avatar-3.jpg"-->
<!--                alt="..."-->
<!--              />-->
<!--            </div>-->
<!--            <div class="text">-->
<!--              <div class="testimonial-quote">-->
<!--                <i class="fas fa-quote-right"></i>-->
<!--              </div>-->
<!--              <p class="testimonial-text">-->
<!--                Lorem Ipsum is simply dummy text of the printing and typesetting-->
<!--                industry. Lorem Ipsum has been the industry's standard dummy-->
<!--                text ever-->
<!--              </p>-->
<!--              <strong>Jessica Watson</strong>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="swiper-pagination"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
<!--<section class="py-6 bg-gray-100">-->
<!--  <div class="container">-->
<!--    <div class="row mb-5">-->
<!--      <div class="col-md-8">-->
<!--        <p class="subtitle text-secondary">Stories from around the globe</p>-->
<!--        <h2>From our travel blog</h2>-->
<!--      </div>-->
<!--      <div class="col-md-4 d-md-flex align-items-center justify-content-end">-->
<!--        <a class="text-muted text-sm" href="blog.html">-->
<!--          See all articles<i class="fas fa-angle-double-right ms-2"></i-->
<!--        ></a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      &lt;!&ndash; blog item&ndash;&gt;-->
<!--      <div class="col-lg-4 col-sm-6 mb-4 hover-animate">-->
<!--        <div class="card shadow border-0 h-100">-->
<!--          <a href="post.html"-->
<!--            ><img-->
<!--              class="img-fluid card-img-top"-->
<!--              src="assets/img/photo/photo-1449034446853-66c86144b0ad.jpg"-->
<!--              alt="..."-->
<!--          /></a>-->
<!--          <div class="card-body">-->
<!--            <a-->
<!--              class="text-uppercase text-muted text-sm letter-spacing-2"-->
<!--              href="#"-->
<!--              >Travel-->
<!--            </a>-->
<!--            <h5 class="my-2">-->
<!--              <a class="text-dark" href="post.html"-->
<!--                >Autumn fashion tips and tricks-->
<!--              </a>-->
<!--            </h5>-->
<!--            <p class="text-gray-500 text-sm my-3">-->
<!--              <i class="far fa-clock me-2"></i>January 16, 2016-->
<!--            </p>-->
<!--            <p class="my-2 text-muted text-sm">-->
<!--              Pellentesque habitant morbi tristique senectus. Vestibulum tortor-->
<!--              quam, feugiat vitae, ultricies ege...-->
<!--            </p>-->
<!--            <a class="btn btn-link ps-0" href="post.html"-->
<!--              >Read more<i class="fa fa-long-arrow-alt-right ms-2"></i-->
<!--            ></a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; blog item&ndash;&gt;-->
<!--      <div class="col-lg-4 col-sm-6 mb-4 hover-animate">-->
<!--        <div class="card shadow border-0 h-100">-->
<!--          <a href="post.html">-->
<!--            <img-->
<!--              class="img-fluid card-img-top"-->
<!--              src="assets/img/photo/photo-1519867850-74775a87e783.jpg"-->
<!--              alt="..."-->
<!--          /></a>-->
<!--          <div class="card-body">-->
<!--            <a-->
<!--              class="text-uppercase text-muted text-sm letter-spacing-2"-->
<!--              href="#"-->
<!--              >Living-->
<!--            </a>-->
<!--            <h5 class="my-2">-->
<!--              <a class="text-dark" href="post.html">Newest photo apps </a>-->
<!--            </h5>-->
<!--            <p class="text-gray-500 text-sm my-3">-->
<!--              <i class="far fa-clock me-2"></i>January 16, 2016-->
<!--            </p>-->
<!--            <p class="my-2 text-muted text-sm">-->
<!--              ellentesque habitant morbi tristique senectus et netus et-->
<!--              malesuada fames ac turpis egestas. Vestibu...-->
<!--            </p>-->
<!--            <a class="btn btn-link ps-0" href="post.html"-->
<!--              >Read more<i class="fa fa-long-arrow-alt-right ms-2"></i-->
<!--            ></a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; blog item&ndash;&gt;-->
<!--      <div class="col-lg-4 col-sm-6 mb-4 hover-animate">-->
<!--        <div class="card shadow border-0 h-100">-->
<!--          <a href="post.html"-->
<!--            ><img-->
<!--              class="img-fluid card-img-top"-->
<!--              src="assets/img/photo/photo-1482463084673-98272196658a.jpg"-->
<!--              alt="..."-->
<!--          /></a>-->
<!--          <div class="card-body">-->
<!--            <a-->
<!--              class="text-uppercase text-muted text-sm letter-spacing-2"-->
<!--              href="#"-->
<!--              >Travel-->
<!--            </a>-->
<!--            <h5 class="my-2">-->
<!--              <a class="text-dark" href="post.html"-->
<!--                >Best books about Photography-->
<!--              </a>-->
<!--            </h5>-->
<!--            <p class="text-gray-500 text-sm my-3">-->
<!--              <i class="far fa-clock me-2"></i>January 16, 2016-->
<!--            </p>-->
<!--            <p class="my-2 text-muted text-sm">-->
<!--              Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit-->
<!--              amet, ante. Mauris placerat eleif...-->
<!--            </p>-->
<!--            <a class="btn btn-link ps-0" href="post.html"-->
<!--              >Read more<i class="fa fa-long-arrow-alt-right ms-2"></i-->
<!--            ></a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
