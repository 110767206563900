import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";

interface Book {
  id: number;
  name: string;
  author: string;
  buybox_id: string;
  bb_oid: number;
}

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent {
  constructor(private readonly http: HttpClient) {}

  books: Book[] | null = null;
  amount: number = 2;

  getBooks() {
    this.http
      .get<Book[]>(
        `https://api.bookrandom.com/books?amount=${this.amount}&list=`
      )
      .subscribe((books) => {
        this.books = books;
        setTimeout(() => {
          this.reload();
        }, 100);
      });
  }

  reload() {
    const bb = (window as any).bb;
    console.log("window", bb);
    bb.forceReload();
  }
}
