import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingComponent } from "./landing/landing.component";

const routes: Routes = [
  { path: "", component: LandingComponent },
  {
    path: "login",
    loadComponent: () =>
      import("./login/login.component").then((mod) => mod.LoginComponent),
  },
  {
    path: "books",
    loadComponent: () =>
      import("./picked-books/picked-books.component").then(
        (mod) => mod.PickedBooksComponent
      ),
  },
  { path: ":404", redirectTo: "/", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
